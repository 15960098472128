import React from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { Router } from "@reach/router";
import './index.css';

import UserAuthProvider, { UserAuthContext } from './web/public/contexts/userAuth';
import Mainheader from './web/public/components/Header/MainHeader';
import OverallFilterProvider, { OverallFilterContext } from './web/public/contexts/overallFilters';
import InventoryFiltersProvider from './web/public/contexts/inventoryFilters';
import FlowCellListProvider from './web/public/contexts/flowCellList';
import Notification from './web/public/components/UI/Notification';
import NotificationProvider, { NotificationContext } from './web/public/contexts/notification';
import CountermeasuresProvider from './web/public/contexts/countermeasures';
import Conditional from './web/public/components/UI/Conditional';
import HuddleStaffingProvider from './web/public/contexts/huddleData';


const ParentContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${props => props.page === '/countermeasures' ? 'auto 1.85fr' : '0.15fr 1.85fr'};
    gap: 0px 0px;
    grid-template-areas:
      "Header"
      "Main";
`;

const HeaderContainer = styled.div`
    grid-area: Header;
`;
const MainBodyContainer = styled.div`
    grid-area: Main;
    background: var(--main-gray);
    overflow-y: auto;
    display: flex;
`;

const NotificationBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;

`;


const Login = React.lazy(() => import("./web/public/components/Pages/Login"));
const Huddle = React.lazy(() => import('./web/public/components/Pages/Huddle'));
const Inventory = React.lazy(() => import('./web/public/components/Pages/Inventory'));
const Countermeasures = React.lazy(() => import('./web/public/components/Pages/Countermeasures'));


function App() {

  return (
    <UserAuthProvider>
      <NotificationProvider>
        <OverallFilterProvider>
          <InventoryFiltersProvider>
            <AuthenticateUser />
          </InventoryFiltersProvider>
        </OverallFilterProvider>
      </NotificationProvider>
    </UserAuthProvider>
  )


};

function AuthenticateUser() {
  const { user, checkLoginStatus } = React.useContext(UserAuthContext);
  const { filter } = React.useContext(OverallFilterContext);
  const { notificationQueue } = React.useContext(NotificationContext);

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [initialCheck, setInitialCheck] = React.useState(false);

  React.useEffect(() => {
    const checkStatus = async () => {
      setLoggedIn(await checkLoginStatus());
      setInitialCheck(true);
    };
    checkStatus();
  }, [checkLoginStatus])


  //loggedIn --> checks if the user has previously logged in and still has valid autnentication
  //initialCheck --> ensure that the useEffect is ran before rendering anything (prevents query of data on first load and getting the notification)
  //user --> goes along with loggedIn. Checks if user object even exists in storage before render main pages
  return (
    <>
      <Conditional if={loggedIn && initialCheck && user}>
        <FlowCellListProvider>
          <HuddleStaffingProvider>
            <CountermeasuresProvider>
              <ParentContainer page={filter.page}>
                <NotificationBody>
                  {notificationQueue.current.map((x, i) => {
                    return (
                      <React.Fragment key={`notification-${i}`}>
                        <Notification status={x.status} info={x.textToDisplay} id={x.id} />
                      </React.Fragment>
                    )
                  })}
                </NotificationBody>


                <HeaderContainer>
                  <Mainheader />
                </HeaderContainer>
                <MainBodyContainer>
                  <React.Suspense fallback={<div> Loading... </div>}>
                    <Router primary={false} component={React.Fragment}>
                      <Huddle exact path="/huddle" component={Huddle} />
                      <Inventory exact path="/inventory" component={Inventory} />
                      <Countermeasures exact path="/countermeasures" component={Countermeasures} />
                      <Huddle default />
                    </Router>
                  </React.Suspense>
                </MainBodyContainer>
              </ParentContainer>
            </CountermeasuresProvider>
          </HuddleStaffingProvider>
        </FlowCellListProvider>
      </Conditional>

      <Conditional if={!loggedIn && initialCheck && !user}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <NotificationBody>
            {notificationQueue.current.map((x, i) => {
              return (
                <React.Fragment key={`notification-${i}`}>
                  <Notification status={x.status} info={x.textToDisplay} id={x.id} />
                </React.Fragment>
              )
            })}
          </NotificationBody>
          <Router primary={false} component={React.Fragment}>
            <Login exact path="/login" component={Login} />
            <Login default />
          </Router>
        </React.Suspense>
      </Conditional>
    </>
  )
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

