import React from 'react';
import styled from 'styled-components';
import { Menu32, Logout32, Idea32, Headset32, UserAdmin32 } from '@carbon/icons-react';
import { UserAuthContext } from '../../contexts/userAuth';
import { Button, Dropdown, Modal } from 'carbon-components-react';
import { OverallFilterContext } from '../../contexts/overallFilters';
import LogoIcon from '../Icons/Logo';
import FlowCell from '../Charts/FlowCell';
import { FlowCellListContext } from '../../contexts/flowCellList';
import Conditional from '../UI/Conditional';
import { queryAdminURL, multiQuery } from '../../../APIs';
import { NotificationContext } from '../../contexts/notification';
import UserIcon from '../Icons/userIcon';

const Container = styled.div`
    .bx--modal-container{
        height: 100%;
        max-height: unset;
        overflow: visible;

    }
    .bx--modal-content{
        overflow: visible;
        margin-bottom: 1em;
    }
    .bx--modal{
        justify-content: flex-start;
        align-items: flex-start;
    }
    .bx--modal-container--xs .bx--modal-header{
        background-color: var(--main-gray-highlight);
        border-bottom: 2px solid var(--secondary-gray);
        padding-top: 0;
    }
    .bx--modal-close:focus{
        border: none;
    }
    //setting height of carbon design drop down menu
    .bx--list-box .bx--list-box__field[aria-expanded='true'] + .bx--list-box__menu {
        max-height: 350px;
    }
`;

const MenuBtn = styled(Button)`
    background-color: transparent !important;
    &:focus{
        outline: none !important;
        box-shadow: none;
        border-color: transparent !important;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5em 0;
    
    svg{
        height: 5em;
        width: auto;
    }
`;

const MenuBody = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
`;

const MenuBodyTop = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const MenuBodyMid = styled.div`
    display: flex;
    flex-direction: column;
`;

const FlowCellContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 3em 0 0 0;
    position: relative;
`;

const FlowCellOwnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--title-text);
    font-weight: bold;
    margin-top: 1.5em;
    #fcOwnerName{
        font-size: 1.35rem;
        
        color: var(--secondary-text);
    }
`;

const MenuBodyBot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-text);
    svg{
        height: 3rem;
        width: 3rem;
        margin-right: .6em;
        fill: #666666
    }

`;
const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span{
        font-weight: bold;
        font-size: 1.15rem;
    }
`;

export default function SideMenu(props) {

    const [open, setOpen] = React.useState(false);

    return (
        <Container>
            <MenuBtn
                renderIcon={Menu32}
                iconDescription='Menu Icon'
                hasIconOnly
                onClick={() => setOpen(true)}
            />
            <Modal
                open={open}
                onRequestClose={() => setOpen(false)}
                passiveModal
                modalHeading={<ModalHeader />}
                size='xs'
            >
                <MenuUI />
            </Modal>
        </Container >
    )
}

function ModalHeader() {
    return (
        <LogoContainer>
            <LogoIcon />
        </LogoContainer>
    )
}

function MenuUI() {
    const { logout, user } = React.useContext(UserAuthContext);
    const { filter, setFlowCell } = React.useContext(OverallFilterContext);
    const { flowCells } = React.useContext(FlowCellListContext);
    const { buildNotification, statusMap } = React.useContext(NotificationContext);

    const [flowCellOwner, setFlowCellOwner] = React.useState('');

    const items = flowCells.map(x => {
        return {
            id: x.id,
            label: x.name
        }
    });

    function checkAdmin() {
        const approvedGroups = ['Consultant', 'App_Administrator'];
        return user.groups.some(g => approvedGroups.includes(g)) ? true : false;
    }

    async function goToAdmin(ev) {
        try {
            let id = ev.currentTarget.id;
            document.getElementById(id).blur();
            let result = await multiQuery({}, queryAdminURL)
            window.open(result.data.url, "_blank");
        } catch (err) {
            if (err.message === 'Expired session') return await logout();
            buildNotification(statusMap.error, `${err.data}`);
        }

    }

    //removes the tooltip after clicking the button and navigating to new tab

    function goToIdeas(ev) {
        let id = ev.currentTarget.id;
        document.getElementById(id).blur();
        window.open('https://ideas.rcmnow.com/', "_blank");
    }
    function goToSupport(ev) {
        let id = ev.currentTarget.id;
        document.getElementById(id).blur();
        window.open('https://pmsquare.com/rcmnow-qa', "_blank");
    }


    return (
        <MenuBody>
            <div>
                <MenuBodyTop>
                    <Conditional if={checkAdmin()}>
                        <MenuBtn
                            renderIcon={UserAdmin32}
                            iconDescription='Admin'
                            tooltipPosition="top"
                            hasIconOnly
                            kind='ghost'
                            id='adminBtn'
                            onClick={(ev) => goToAdmin(ev)}
                        />
                    </Conditional>
                    <MenuBtn
                        renderIcon={Headset32}
                        iconDescription='Support'
                        tooltipPosition="top"
                        hasIconOnly
                        kind='ghost'
                        id='supportBtn'
                        onClick={(ev) => goToSupport(ev)}
                    />
                    <MenuBtn
                        renderIcon={Idea32}
                        iconDescription='Ideas Portal'
                        tooltipPosition="top"
                        hasIconOnly
                        kind='ghost'
                        id='ideasPortalBtn'
                        onClick={(ev) => goToIdeas(ev)}
                    />
                    <MenuBtn
                        renderIcon={Logout32}
                        iconDescription='Log out'
                        tooltipPosition="top"
                        hasIconOnly
                        kind='ghost'
                        onClick={() => logout(true)}
                    />
                </MenuBodyTop>
                <MenuBodyMid>
                    <Dropdown
                        id='cmStatus'
                        items={items}
                        label=''
                        titleText='Flow Cell:'
                        selectedItem={filter.flowCell.name}
                        onChange={e => { setFlowCell(e.selectedItem ? e.selectedItem : null) }}
                    />
                    <FlowCellContainer>
                        <FlowCell setFlowCellOwner={setFlowCellOwner} />
                    </FlowCellContainer>
                    <Conditional if={filter.flowCell.name !== 'All Flow Cells'}>
                        <FlowCellOwnerContainer>
                            <span>Flow Cell Owner:</span>
                            <span id='fcOwnerName'>{flowCellOwner}</span>
                        </FlowCellOwnerContainer>
                    </Conditional>

                </MenuBodyMid>
            </div>
            <MenuBodyBot>
                <UserIcon />
                <UserInfo>
                    <span>{user.firstName} {user.lastName}</span>
                    <small>{user.mappedRole}</small>
                </UserInfo>
            </MenuBodyBot>
        </MenuBody>
    )
}
