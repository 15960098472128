import * as React from "react";

function UserIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
            <path d="M16 31.36C7.53 31.36.64 24.47.64 16S7.53.64 16 .64 31.36 7.53 31.36 16 24.47 31.36 16 31.36zM6.349 27A14.575 14.575 0 0016 30.64c3.693 0 7.072-1.374 9.65-3.64h-.01c0-4.341-2.941-8.161-7.153-9.29a.359.359 0 01-.077-.665 5.134 5.134 0 002.72-4.534c0-2.84-2.306-5.151-5.14-5.151s-5.141 2.311-5.141 5.151a5.134 5.134 0 002.72 4.534.36.36 0 01-.076.665A9.632 9.632 0 006.36 27h-.011zm13.086-9.75c3.913 1.377 6.646 4.973 6.905 9.104A14.593 14.593 0 0030.64 16c0-8.073-6.567-14.64-14.64-14.64S1.36 7.927 1.36 16c0 4.04 1.645 7.703 4.3 10.354a10.357 10.357 0 016.883-9.104 5.852 5.852 0 01-2.415-4.739c0-3.237 2.629-5.871 5.86-5.871 3.232 0 5.861 2.633 5.861 5.871a5.848 5.848 0 01-2.414 4.739z" />
            <path fill="none" d="M0 0h32v32H0z" />
        </svg>
    )
}

export default UserIcon;
