import React from 'react';
import styled from 'styled-components';
import { Button } from 'carbon-components-react';


const CustomBtn = styled(Button)`
    color: #1F57A4;
    background: #FFFFFF;
    border-color: #1F57A4;
    &:hover, &:active, &:focus{
        background: #1F57A4;
        color: #FFFFFF;
    }
`;

export default function HeaderRowButton({ text, size, fct = null, disabled = false }) {
    //text: button display name
    //size: carbon design values for button size 'default' | 'field' | 'small' | 'sm' | 'lg' | 'xl'
    //fct: function for button click

    return (
        <>
            <CustomBtn
                kind='tertiary'
                size={size}
                onClick={fct ? fct : () => { }}
                disabled={disabled}
            >
                {text}
            </CustomBtn>
        </>
    )
}
