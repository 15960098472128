import React from 'react';
import styled from 'styled-components';
import { Events32 } from '@carbon/icons-react';
import HorizontalMetricValue from '../UI/HorizontalMetricValue';
import { NumberInput } from 'carbon-components-react';
import HeaderRowButton from '../UI/HeaderRowButton';
import * as API from '../../../APIs';
import { formatDateForMDX, isSameDay, showElement } from '../../utils';

import { OverallFilterContext } from '../../contexts/overallFilters';
import { NotificationContext } from '../../contexts/notification';
import { UserAuthContext } from '../../contexts/userAuth';
import { HuddleDataContext } from '../../contexts/huddleData';
import Conditional from '../UI/Conditional';
import HuddleProgressionMini from '../Charts/HuddleProgressionMini';

const Container = styled.div`
    display: flex;
    width: 100%;
    padding: .75em 1em;
`;

const UserBlock = styled.div`
    color: var(--title-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-right: 1em;

   
    @media (min-width: 950px) {
        margin-right: 5em;
        flex-direction: row;
    }
`;

const UserBlockData = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;

    span{
        margin-left: .5em;
    }

    @media (min-width: 950px) {
        font-size: 1.15rem;
    }
`;

const MetricBlock = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const ChartBlock = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    @media (min-width: 1125px) {
        width: 35%;
    }

`;

const Chart = styled.span`
    width: 75%;
    padding-right: 2em;
`;

const UpdateInput = styled.div`
    display: flex;
    align-items: center;
    
    .bx--number input[type='number']{
        min-width: unset;
        padding-right: 0;
        width: 5em;
        border: none;
    }
    .bx--number__controls{
        display: none; //remove the increment dialer
    }
    .bx--btn{
        padding: 0 .6em;
    }

    @media (min-width: 950px) {
        margin-left: 1em;
    }
`;
export default function HuddleRow(props) {

    const { filter, setPage } = React.useContext(OverallFilterContext);
    const [headers, setHeaders] = React.useState([]);
    const [staffingChart, setStaffingChart] = React.useState({
        chart: null,
        avg: null
    });
    const { logout, user } = React.useContext(UserAuthContext);
    const { huddle } = React.useContext(HuddleDataContext);
    const { buildNotification, statusMap } = React.useContext(NotificationContext);

    React.useEffect(() => {
        if (!huddle.staffingHeader) return;
        setHeaders([
            {
                title: filter.date.toLocaleDateString('en-US', {
                    month: 'short', day: '2-digit'
                }),
                value: huddle.staffingHeader.data.Cells[0].Value
            },
            {
                title: 'Typical',
                value: huddle.staffingHeader.data.Cells[1].Value
            },
            // Ordinal 2 value will be used in the future
            {
                title: 'Needed to achieve goal',
                value: huddle.staffingHeader.data.Cells[3].Value
            },
            {
                title: 'Needed to meet forecasted demand',
                value: huddle.staffingHeader.data.Cells[4].Value
            },
        ]);

        let chartData = huddle.staffingChart;
        const yAxis = chartData.data.Cells;

        let divisor = isSameDay(new Date(), filter.date) ? yAxis.length - 1 : yAxis.length;
        const avg = yAxis.map(x => Math.round(x.Value)).reduce((a, b) => (a + b)) / divisor;
        setStaffingChart({
            chart: chartData,
            avg: { title: '30d Avg', value: avg ? avg.toFixed(1) : '' }
        });
    }, [huddle, filter.date]);


    const [inputNum, setInputNum] = React.useState(''); //or pull number if stored somewhere
    const updateNumber = async function () {

        try {
            let date = formatDateForMDX(filter.date);
            let currentDate = formatDateForMDX(new Date());
            await API.multiQuery({
                label: "Staffing",
                date: date,
                currentDate: currentDate,
                flowCell: filter.flowCell.id,
                inputVal: inputNum
            }, API.updateStaffing);
            buildNotification(statusMap.success, `Successfully updated staffing level to ${inputNum} for ${filter.flowCell.name} on ${date}`);
            setInputNum('');
            setPage('/huddle'); //forcing the huddle page to requery data after staffing change
        } catch (err) {
            if (err.message === 'Expired session') return await logout();
            buildNotification(statusMap.error, `Unable to update staffing level to ${inputNum} for ${filter.flowCell.name}`);
            setInputNum('');
        }
    }


    return (
        <Container>
            <UserBlock>
                <UserBlockData>
                    <Events32 />
                    <span>Staffing</span>
                </UserBlockData>

                <Conditional if={showElement(filter, user, filter.date)}>
                    <UpdateInput>
                        <NumberInput
                            id="updateNumberInput"
                            size="sm"
                            allowEmpty
                            value={inputNum}
                            onChange={e => setInputNum(e.target.value)}
                        />
                        <HeaderRowButton
                            text='Update'
                            size='sm'
                            fct={updateNumber}
                            disabled={inputNum === '' || !inputNum}
                        />
                    </UpdateInput>
                </Conditional>
            </UserBlock>
            <MetricBlock>
                {headers.map((x, index) => (
                    <React.Fragment key={`metric-${index}`}>
                        <HorizontalMetricValue data={x} />
                    </React.Fragment>
                )
                )}
                <Conditional if={staffingChart.chart}>
                    <ChartBlock>
                        <Chart>
                            <HuddleProgressionMini
                                data={staffingChart.chart}
                                show30dAvg={false}
                            />
                        </Chart>

                        <HorizontalMetricValue data={staffingChart.avg} />

                    </ChartBlock>
                </Conditional>
            </MetricBlock>
        </Container >
    )
}
