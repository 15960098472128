import React from 'react';
import styled from 'styled-components';
import SideMenu from './SideMenu';
import PageSelector from './PageSelector';
import Calendar from './Calendar';
import HuddleRow from './HuddleRow';
import InventoryRow from './InventoryRow';
import AddCounterMeasure from './AddCounterMeasure';
import Conditional from '../UI/Conditional';
import { OverallFilterContext } from '../../contexts/overallFilters';
import { UserAuthContext } from '../../contexts/userAuth';
import { showElement } from '../../utils';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${props => props.page === '/countermeasures' ? '1fr' : '3.5em 1.5fr'};
    gap: 0px 0px;
    grid-template-areas:
        "top"
        "bottom";

    height: 100%;

    @media (min-width: 950px) {
        grid-template-rows: ${props => props.page === '/countermeasures' ? '1fr' : '3.5em 1fr'};
    }
`;

const Top = styled.div`
    grid-area: top;
    background: var(--dark-header);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    &>div{
        width: 33%;
    }
    &>div:nth-child(2){
        display: flex;
        justify-content: center;
    }
    &>div:last-child{
        display: flex;
        justify-content: flex-end;
        align-items: center;

    }

    @media (min-width: 950px) {
        font-size: 1.15rem;
    }


`;

const FlowCell = styled.div`
    display: flex;
    align-items: center;
    font-size: .75rem;

    @media (min-width: 700px){
        font-size: 1rem;
    }

    @media (min-width: 950px){
        font-size: inherit;
    }
`;

const Bottom = styled.div`
    grid-area: bottom; 
    display: flex;
    box-shadow: 0px 0px 6px #afafaf;
    position: relative;
    z-index: 50;
`;

const CalendarCover = styled.span`
    display: ${props => props.page === '/countermeasures' ? 'none' : 'block'};
`;

export default function Mainheader(props) {
    const { filter, setDate } = React.useContext(OverallFilterContext);
    const { user } = React.useContext(UserAuthContext);
    const [ranges, setRanges] = React.useState({
        min: null,
        max: null
    });

    React.useEffect(() => {
        setRanges({
            min: null,
            max: new Date().toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            })
        })
    }, []);

    return (
        <Container page={filter.page}>
            <Top>
                <FlowCell>
                    <SideMenu />
                    <Conditional if={filter.page !== '/inventory'}>
                        <span>{filter.flowCell.name}</span>
                    </Conditional>
                </FlowCell>
                <PageSelector />
                <div>
                    <CalendarCover page={filter.page}>
                        <Calendar
                            headerCalendar
                            fct={setDate}
                            passInDate={filter.date}
                            ranges={ranges}

                        />
                    </CalendarCover>
                    <Conditional if={showElement(filter, user) && filter.page !== '/inventory'}>
                        <AddCounterMeasure />
                    </Conditional>

                </div>

            </Top>
            <Conditional if={filter.page !== '/countermeasures'}>
                <Bottom>
                    <Conditional if={filter.page === '/huddle'}>
                        <HuddleRow />
                    </Conditional>
                    <Conditional if={filter.page === '/inventory'}>
                        <InventoryRow />
                    </Conditional>
                </Bottom>
            </Conditional>


        </Container>
    )
}
