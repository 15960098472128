import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    margin: .25em 0;
`;

const MetricTitle = styled.span`
    color: var(--title-text);
    border-right: 1pt solid var(--title-text);
    padding: 0 .3em 0 0;
    font-size: .75rem;
    @media (min-width: 1350px) {
        font-size: 1rem;
    }
`;

const MetricValue = styled.span`
    color: var(--secondary-text);
    font-size: .85rem;
    font-weight: bold;
    margin: 0 0 0 .3em;

    @media (min-width: 1350px) {
        font-size: 1.15rem;
    }
`;


export default function HorizontalMetricValue({ data }) {


    return (
        <Container>
            <MetricTitle>{data.title}</MetricTitle>
            <MetricValue>{data.value}</MetricValue>
        </Container>
    )
}
