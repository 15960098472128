//function to adjust the color --> Similar to SASS lighten and darken feature
//Positive numbers to lighten, Negative numbers to darken
//uses hex colors
module.exports.lightenDarkenColor = function (color, amount) {
    let usePound = false;

    if (color[0] === "#") {
        color = color.slice(1);
        usePound = true;
    }

    let num = parseInt(color, 16);

    let r = (num >> 16) + amount;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000FF) + amount;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
}


//Used to format dates in the MMM dd, yyyy format and return the string value
module.exports.formatDate = function (date) {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    let stringVal = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    return stringVal;
}

//adds days to a passed in date and returns the new calculated date
module.exports.addDays = function (date, days) {
    let d = new Date(date);
    d.setDate(d.getDate() + days);
    return d;
}

module.exports.compareDatesIgnoreTime = function (d1, d2) {
    let compare1 = new Date(d1).setHours(0, 0, 0, 0);
    let compare2 = new Date(d2).setHours(0, 0, 0, 0);
    return compare1 === compare2 ? true : false;
}

module.exports.formatDateForMDX = function (date) {
    return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
}

module.exports.abbreviate = function (number, maxPlaces, forceLetter) {
    number = Number(number)
    forceLetter = forceLetter || false
    if (forceLetter !== false) {
        return annotate(number, maxPlaces, forceLetter)
    }
    var abbr
    if (number >= 1e12 || number <= -1e12) {
        abbr = 'T'
    }
    else if (number >= 1e9 || number <= -1e9) {
        abbr = 'B'
    }
    else if (number >= 1e6 || number <= -1e6) {
        abbr = 'M'
    }
    else if (number >= 1e3 || number <= -1e3) {
        abbr = 'K'
    }
    else {
        abbr = ''
    }
    return annotate(number, maxPlaces, abbr)
}

function annotate(number, maxPlaces, abbr) {

    if (number.toString().length <= 4) return module.exports.formatWithCommas(number);
    // set places to false to not round
    var rounded = 0
    switch (abbr) {
        case 'T':
            rounded = number / 1e12
            break
        case 'B':
            rounded = number / 1e9
            break
        case 'M':
            rounded = number / 1e6
            break
        case 'K':
            rounded = number / 1e3
            break
        case '':
            rounded = number
            break
        default:
            rounded = number
    }
    if (maxPlaces !== false) {
        var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$')
        if (test.test(('' + rounded))) {
            rounded = rounded.toFixed(maxPlaces)
        }
    }
    let places = rounded.toString().length <= 4 ? 1 : 0;
    rounded = Number(rounded).toFixed(places)
    return `${rounded}${abbr}`
}
//traverses the axes property of mdx results maps the values
module.exports.mapAxesData = function (axes) {
    return axes.map(x => {
        return {
            Ordinal: x.Ordinal,
            id: x.Members[0].Name
        }
    })
}

module.exports.formatWithCommas = (number) => {
    if (isNaN(number) || number === null) return number;
    return Math.round(number).toString().replace(/\s/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

module.exports.isSameDay = function (d1, d2) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
};

module.exports.showElement = (filter, user, date = null) => {
    if (
        filter.flowCell.name !== 'All Flow Cell' &&
        user.highestRole !== 'User' &&
        user.allowedFlowCells.some(fc => fc === filter.flowCell.name)
    ) {
        if (date) { //date check for staffing writeback
            if (user.highestRole === 'Consultant' || user.highestRole === 'App_Administrator') return true; //time limit does not apply to these roles
            if (module.exports.getDayDiff(date) <= 8) { //8 for past 7 days and today
                return true;
            } else {
                return false;
            }
        }

        return true;

    } else {
        return false;
    }
}

module.exports.checkChartStatus = (statuses) => {
    if (statuses.includes(true) && statuses.includes(false)) return false;
    if (statuses.includes(true) && !statuses.includes(false)) return true;
    return false;
}

module.exports.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
module.exports.getDayDiff = (date) => {
    let timeDiff = Math.abs(new Date().setHours(23, 59, 59, 999) - new Date(date).setHours(0, 0, 0, 0));
    let diffDay = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return diffDay;
}