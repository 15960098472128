import React from 'react';
import { NotificationContext } from './notification';
import { OverallFilterContext } from './overallFilters';
import { UserAuthContext } from './userAuth';

import * as API from '../../APIs';
import { checkChartStatus, formatDateForMDX } from '../utils';
import ProcessLineChart from '../customHooks/processLineChart';
import ProcessCompareChart from '../customHooks/processCompareChart';

export const HuddleDataContext = React.createContext();

const HuddleDataProvider = (props) => {

    const { filter } = React.useContext(OverallFilterContext);
    const { buildNotification, statusMap } = React.useContext(NotificationContext);
    const { logout } = React.useContext(UserAuthContext);
    const [isLoading, setIsLoading] = React.useState(true);

    const [huddle, setHuddle] = React.useState({
        staffingValue: 0,
        staffingChart: null,
        staffingHeader: null,
        progression: {
            actual30day: {},
            expected30day: {},
            actualVsExpected: {}
        },
        outs: {
            actual30day: {},
            expected30day: {},
            actualVsExpected: {}
        },
        ins: {},
        currentNumber: {},
        currentMoney: {},
        percentToGoal: {},
        percentToGoalMoney: {},
        pareto: [],
        invChart: {
            data: {
                chartConfig: {},
                overall: {},
                charts: [],
                breakout: null,
                chosenFlowCell: {},
                dates: []
            },
            status: true

        }
    });



    React.useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {

            const date = formatDateForMDX(filter.date);
            const flowCell = filter.flowCell.id;

            try {
                const [
                    progActual30day,
                    progExpected30day,
                    progActualVsExpected,
                    outsActual30day,
                    outsExpected30day,
                    outsActualVsExpected,
                    ins30day,
                    pareto,
                    staffingData,
                    staffingChartData,
                    inventoryLine,
                ] = await API.multiQuery({ label: "Huddle", date: date, flowCell: flowCell },
                    API.queryHuddleProgression30DayActual,
                    API.queryHuddleProgression30DayExpected,
                    API.queryProgressionActualVsExpected,
                    API.queryHuddleOuts30DayActual,
                    API.queryHuddleOuts30DayExpected,
                    API.queryOutsActualVsExpected,
                    API.queryHuddleIns30Day,
                    API.queryParetoChart,
                    API.queryStaffing,
                    API.queryStaffingChart,
                    API.queryInventoryLineChart
                );

                // if an error is thrown, all results are undefined. We only need to check one

                if (!progActual30day) return;

                let inventoryStatus;
                if (inventoryLine && inventoryLine.data) {
                    let flowCell = filter.flowCell.name;
                    inventoryLine.data.chosenFlowCell = flowCell === 'All Flow Cells' ?
                        inventoryLine.data.overall :
                        inventoryLine.data.charts.find(x => x.title === flowCell);

                    inventoryLine.data.chosenFlowCell.moneyChart = ProcessLineChart({
                        current: inventoryLine.data.chosenFlowCell.moneyData,
                        goal: inventoryLine.data.chosenFlowCell.moneyToGoal,
                        percChange: inventoryLine.data.chosenFlowCell.moneyPercChange,
                        isMoney: true,
                        isCreditFC: inventoryLine.data.chosenFlowCell.isCreditFC
                    });
                    inventoryLine.data.chosenFlowCell.numberChart = ProcessLineChart({
                        current: inventoryLine.data.chosenFlowCell.numberData,
                        goal: inventoryLine.data.chosenFlowCell.numberToGoal,
                        percChange: inventoryLine.data.chosenFlowCell.numberPercChange,
                        isCreditFC: inventoryLine.data.chosenFlowCell.isCreditFC
                    });
                    let statuses = [inventoryLine.data.chosenFlowCell.moneyChart.chartStatus, inventoryLine.data.chosenFlowCell.numberChart.chartStatus];
                    inventoryStatus = checkChartStatus(statuses);
                }

                let progressionVsChart = ProcessCompareChart({ data: progActualVsExpected, card: 'progression', date: filter.date });
                let outsVsChart = ProcessCompareChart({ data: outsActualVsExpected, card: 'outs', date: filter.date });

                setHuddle(
                    {
                        staffingValue: staffingData.data.Cells[0].Value ? staffingData.data.Cells[0].Value : 0,
                        staffingChart: staffingChartData,
                        staffingHeader: staffingData,
                        progression: {
                            actual30day: progActual30day,
                            expected30day: progExpected30day,
                            actualVsExpected: progressionVsChart
                        },
                        outs: {
                            actual30day: outsActual30day,
                            expected30day: outsExpected30day,
                            actualVsExpected: outsVsChart
                        },
                        ins: {
                            ins30day: ins30day
                        },
                        pareto: pareto.data.map(x => {
                            return {
                                id: x.id,
                                name: x.name,
                                dates: x.dates.map(date => new Date(date))
                            }
                        }),
                        invChart: {
                            data: inventoryLine.data,
                            goldNuggets: inventoryLine.goldNuggets,
                            status: inventoryStatus
                        }
                    }
                )
            } catch (err) {
                if (err.message === 'Expired session') return await logout();
                buildNotification(statusMap.error, `${err}`);
                setIsLoading(false);
                return [];
            }

            setIsLoading(false);
        }
        if (filter.page === '/huddle') fetchData();
    }, [filter, buildNotification, statusMap.error, logout]);


    return (
        <HuddleDataContext.Provider value={{ huddle, isLoading }}>
            {props.children}
        </HuddleDataContext.Provider>
    )
}

export default HuddleDataProvider;