import React from 'react';
import styled from 'styled-components';


const Tooltip = styled.div`
    display: none;
    position: absolute;
    background: #393939;
    color: #ffffff;
    font-size: .875rem;
    max-width: 15rem;
    margin: .5rem 0 0 .5rem;
    padding: 1rem;
    white-space: nowrap;

`;

export default function CarbonToolTip({ id = null, body }) {
    //id: optional value if tooltip needs to be identified somewhere
    //body: custom component that is passed in. This is whatever you want to display in any format

    return (
        <Tooltip id={id}>
            {body}
        </Tooltip>
    )
}
