import { ToastNotification } from 'carbon-components-react';
import React from 'react';
import styled from 'styled-components';
import { NotificationContext } from '../../contexts/notification';


const NotificationContainer = styled.div`
    .bx--toast-notification{
        position: relative;
        z-index: 10000;
    }
`;
export default function Notification({ status, info, id }) {

    const { removeNotification } = React.useContext(NotificationContext);

    const close = function (id) {
        removeNotification(id);
    }

    return (
        <>
            {
                <NotificationContainer id={id}>
                    <ToastNotification
                        kind={status}
                        title=''
                        subtitle={info}
                        timeout={9000}
                        onCloseButtonClick={() => close(id)}
                        caption=''
                    />
                </NotificationContainer>
            }
        </>

    )
}
