import React from 'react';
import styled from 'styled-components';
import { Dropdown } from 'carbon-components-react';
import { navigate } from '@reach/router';
import { OverallFilterContext } from '../../contexts/overallFilters';

const Container = styled.div`
        .bx--dropdown__wrapper--inline {
            grid-gap: 0;
        }
`;

const PageDrop = styled(Dropdown)`

        background-color: transparent !important;
        span, svg {
            color: #FFFFFF;
            fill: #FFFFFF;
            font-size: .75rem;

            @media (min-width: 700px){
                font-size: .875rem;
            }
        }
        
        button:focus, div:focus{
            outline: none;
        }


`;


export default function PageSelector(props) {
    const { filter, setPage } = React.useContext(OverallFilterContext);
    const [selected, setSelected] = React.useState({
        id: 'page_huddle',
        label: 'Huddle',
        path: '/huddle'
    });

    const [items] = React.useState([
        {
            id: 'page_huddle',
            label: 'Huddle',
            path: '/huddle'
        },
        {
            id: 'page_countermeasures',
            label: 'Countermeasures',
            path: '/countermeasures'
        },
        {
            id: 'page_inventory_overview',
            label: 'Inventory Overview',
            path: '/inventory'
        }
    ])

    React.useEffect(() => {
        let page = items.find(x => x.path === filter.page);
        setSelected(page)
    }, [filter.page, items])

    function goTo(path) {
        setPage(path.selectedItem.path);
        navigate(path.selectedItem.path);
    }

    return (
        <Container>
            <PageDrop
                ariaLabel="Page selector"
                id="dropDownPageSelect"
                items={items}
                label={selected.label}
                selectedItem={selected}
                type='inline'
                onChange={(page) => { goTo(page) }}
            />
        </Container>
    )
}
