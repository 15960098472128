//status criteria --> true = green, false = red
//regular FC, below goal = true
//regular FC, above goal = false
//credit FC, number line, below goal = true
//credit FC, number line, above goal = false
//credit FC, money line, below goal = false


export default function ProcessLineChart({ current, goal, percChange, isMoney = false, isCreditFC }) {
    current = current || [];
    goal = goal || [];
    let ranges = [];
    let currentValueZones = [];
    let currentValueBiggerBool = true;

    for (let i = 0; i < current.length; i++) {
        ranges.push([i, current[i], goal[i]]); //push to range array

        if (current[i] < goal[i] && currentValueBiggerBool) {
            currentValueZones.push({
                value: intersect(i - 1, i, current[i - 1], current[i], goal[i - 1], goal[i]),
                fillColor: (isCreditFC && isMoney) ? false : true, //below --> false = red, above --> true = green
            }); //push to zone array 
            currentValueBiggerBool = false;
        } else if (current[i] > goal[i] && !currentValueBiggerBool) {
            currentValueZones.push({
                value: intersect(i - 1, i, current[i - 1], current[i], goal[i - 1], goal[i]),
                fillColor: (isCreditFC && isMoney) ? true : false
            }); //push to zone array 
            currentValueBiggerBool = true;
        }
    }
    //zones color up to a given point, therefore we need to push a color at the end as well:
    let chartStatus;
    if (currentValueBiggerBool) {
        chartStatus = (isCreditFC && isMoney) ? true : false;
        currentValueZones.push({
            value: current.length,
            fillColor: (isCreditFC && isMoney) ? false : true
        })
    } else {
        chartStatus = (isCreditFC && isMoney) ? false : true;
        currentValueZones.push({
            value: current.length,
            fillColor: (isCreditFC && isMoney) ? true : false
        })
    }

    return {
        chartStatus: chartStatus,
        ranges: ranges,
        currentValueZones: currentValueZones,
        current: current,
        goal: goal,
        percChange: percChange
    }
}

function intersect(x1, x2, y1, y2, y3, y4) {
    return ((x2 * y1 - x1 * y2) - (x2 * y3 - x1 * y4)) / ((y4 - y3) - (y2 - y1));
}