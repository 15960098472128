import React from 'react';


export const OverallFilterContext = React.createContext();

const OverallFilterProvider = (props) => {

    let location = window.location.href;
    location = location.match(/(\/countermeasures$)|(\/inventory$)|(\/huddle$)/g) ? //set default to huddle if not matching any of the main pages
        `/${location.split('/').pop()}` :
        '/huddle';

    const [filter, setFilter] = React.useState({
        page: location,
        date: new Date(),
        flowCell: { id: '[Flow Cells].[Flow Cells].[All Flow Cells]', name: 'All Flow Cells' }
    });

    const setPage = (page) => {
        setFilter({
            ...filter,
            page: page
        });
    }

    const setFlowCell = (flowCell) => {
        if (!flowCell) flowCell = { id: '[Flow Cells].[Flow Cells].[All Flow Cells]', name: 'All Flow Cells' };
        setFilter({
            ...filter,
            flowCell: { id: flowCell.id, name: flowCell.label }
        });
    }

    const setDate = (date) => {
        setFilter({
            ...filter,
            date: date
        })
    }

    return (
        <OverallFilterContext.Provider value={{ filter, setPage, setFlowCell, setDate }}>
            {props.children}
        </OverallFilterContext.Provider>
    )
}

export default OverallFilterProvider;