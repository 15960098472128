import React from 'react';
import styled from 'styled-components';
import { MultiSelect } from 'carbon-components-react';

const DropDown = styled(MultiSelect.Filterable)`

        input{
            border-bottom: none;
            background-color: #FFFFFF;
        }

        button:focus, div:focus{
            outline: none;
        }
        .bx--tag--high-contrast{
            width: 5em;
        }

        padding-left: .5rem;
        


`;

export default function MultiSelectFlowCell(props) {

    const [items, setItems] = React.useState([]);

    React.useEffect(() => {
        setItems(
            props.data.map(x => {
                return { id: x.id, label: x.name }
            })
                .filter(x => x.label !== 'All Flow Cells')
        );

    }, [props.data]);

    function applyChange(e) {
        props.detectOpen(e);
        if (!e) props.closeFunction(); //only perform function when drop down is closed 
    }

    return (
        <>
            <DropDown
                ariaLabel="Flow Cell Select"
                id="fcSelect"
                items={items}
                initialSelectedItems={props.initialSelectedItems.length > 0 ? props.initialSelectedItems : items.map(x => x)}
                placeholder='Click here to search'
                onChange={props.onChange}
                onMenuChange={e => applyChange(e)}
            />
        </>
    )
}
