import * as React from "react";
import styled from "styled-components";
import { NotificationContext } from "../../contexts/notification";
import { OverallFilterContext } from "../../contexts/overallFilters";
import { UserAuthContext } from "../../contexts/userAuth";
import { lightenDarkenColor } from "../../utils";
import CarbonToolTip from "../UI/CarbonToolTip";
import { queryFlowCellData, multiQuery } from '../../../APIs';
import Conditional from "../UI/Conditional";

const SVG = styled.svg`
    overflow: visible;
    g:hover {
        cursor: default;
        path{
            stroke-opacity: .975;
        }
    }

`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
`;

function FlowCellTooltipBody({ data }) {
    const { filter } = React.useContext(OverallFilterContext);


    return (
        <Body>
            <span>{data.category}</span>
            <Conditional if={filter.flowCell.name === 'All Flow Cells'}>
                <span>{data.value} Flow {data.value <= 1 ? 'Cell' : 'Cells'} Aligned</span>
            </Conditional>
            <Conditional if={filter.flowCell.name !== 'All Flow Cells'}>
                <span>{data.value === 1 ? 'Aligned' : 'In-Progress'}</span>
            </Conditional>
        </Body>
    )
}

function FlowCell({ setFlowCellOwner }) {
    //setFlowCellOwner --> state update function from side menu 

    const { filter } = React.useContext(OverallFilterContext);
    const { logout } = React.useContext(UserAuthContext);
    const [data, setData] = React.useState({
        VM: {
            category: 'Visual Management',
            value: 0,
            fill: "#b9b9b9",
            stroke: lightenDarkenColor("#b9b9b9", -50)
        },
        Pull: {
            category: 'Pull (Tight Connections)',
            value: 0,
            fill: "#b9b9b9",
            stroke: lightenDarkenColor("#b9b9b9", -50)
        },
        WO: {
            category: '6S Workplace Organization',
            value: 0,
            fill: "#b9b9b9",
            stroke: lightenDarkenColor("#b9b9b9", -50)
        },
        SW: {
            category: 'Standard Work',
            value: 0,
            fill: "#b9b9b9",
            stroke: lightenDarkenColor("#b9b9b9", -50)
        },
        SF: {
            category: 'Simple Flow',
            value: 0,
            fill: "#b9b9b9",
            stroke: lightenDarkenColor("#b9b9b9", -50)
        }
    });
    const { buildNotification, statusMap } = React.useContext(NotificationContext);

    React.useEffect(() => {
        const getData = async () => {
            try {
                let results = await multiQuery({
                    label: 'Flow Cell',
                    date: null,
                    flowCell: filter.flowCell.id
                }, queryFlowCellData)
                setFlowCellOwner(results.data.owner);
                let fcValues = results.data.values;
                const keyPairOrder = mapValueOrder(fcValues);
                for (const key of Object.keys(fcValues)) {
                    let fill = applyColor(fcValues[key].value, keyPairOrder);
                    let stroke = lightenDarkenColor(fill, -50);
                    fcValues[key].fill = fill;
                    fcValues[key].stroke = stroke;
                }
                setData(fcValues)
            } catch (err) {
                if (err.message === 'Expired session') return await logout();
                buildNotification(statusMap.error, `${err}`);
                setData({
                    VM: {
                        category: 'Visual Management',
                        value: 0,
                        fill: "#b9b9b9",
                        stroke: lightenDarkenColor("#b9b9b9", -50)
                    },
                    Pull: {
                        category: 'Pull (Tight Connections)',
                        value: 0,
                        fill: "#b9b9b9",
                        stroke: lightenDarkenColor("#b9b9b9", -50)
                    },
                    WO: {
                        category: '6S Workplace Organization',
                        value: 0,
                        fill: "#b9b9b9",
                        stroke: lightenDarkenColor("#b9b9b9", -50)
                    },
                    SW: {
                        category: 'Standard Work',
                        value: 0,
                        fill: "#b9b9b9",
                        stroke: lightenDarkenColor("#b9b9b9", -50)
                    },
                    SF: {
                        category: 'Simple Flow',
                        value: 0,
                        fill: "#b9b9b9",
                        stroke: lightenDarkenColor("#b9b9b9", -50)
                    }
                });
            }
        }
        getData();
    }, [filter.flowCell, setFlowCellOwner, buildNotification, statusMap, logout]);

    function mapValueOrder(data) {
        let uniqueValues = [...new Set(Object.keys(data).map(x => data[x].value))].sort(function (a, b) { return a - b }).filter(x => x !== 0);
        let keyPair = {};
        uniqueValues.forEach((x, idx) => {
            keyPair[idx] = x;
        });
        return keyPair;
    }

    function applyColor(value, keyPairOrder) {
        if (value === 0) return "#b9b9b9";
        let order = Object.keys(keyPairOrder).find(key => keyPairOrder[key] === value);
        const darkenAmount = {
            0: 0,
            1: -25,
            2: -50,
            3: -75,
            4: -100
        };
        return lightenDarkenColor('#3db14c', darkenAmount[order]);
    }


    function showTooltip(ev, id) {
        let tooltip = document.getElementById(id);
        tooltip.style.display = "block";
        tooltip.style.left = ev.nativeEvent.offsetX + 50 + 'px';
        tooltip.style.top = ev.nativeEvent.offsetY + 20 + 'px';
    }

    function hideTooltip(id) {
        var tooltip = document.getElementById(id);
        tooltip.style.display = "none";
    }

    return (
        <>
            <CarbonToolTip id='tooltipVM' body={<FlowCellTooltipBody data={data.VM} />} />
            <CarbonToolTip id='tooltipPull' body={<FlowCellTooltipBody data={data.Pull} />} />
            <CarbonToolTip id='tooltipWO' body={<FlowCellTooltipBody data={data.WO} />} />
            <CarbonToolTip id='tooltipSW' body={<FlowCellTooltipBody data={data.SW} />} />
            <CarbonToolTip id='tooltipSF' body={<FlowCellTooltipBody data={data.SF} />} />
            <SVG
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 70.176 81.5"
                height="81.5mm"
                width={257.673}
            >

                <g
                    onMouseMove={(ev) => showTooltip(ev, 'tooltipVM')}
                    onMouseOut={() => hideTooltip('tooltipVM')}
                >
                    <path
                        data-cv-key="Visual Management"
                        //very slight differences between these two arcs. Could go with either
                        // d="M 14.2 61.1 l -7.453 20.02 H 61.71 L 54.4 60.7 a 33.118 33.118 0 0 1 -2.518 1.8 a 33.118 33.118 0 0 1 -3.082 1.6 a 33.118 33.118 0 0 1 -3.011 1.152 a 33.118 33.118 0 0 1 -3.289 1.048 a 33.118 33.118 0 0 1 -3.3 0.6 a 33.118 33.118 0 0 1 -3.3 0.3 a 33.118 33.118 0 0 1 -2.4 0 a 33.118 33.118 0 0 1 -3.3 -0.3 a 33.118 33.118 0 0 1 -3.312 -0.473 a 33.118 33.118 0 0 1 -3.2 -0.8 a 33.118 33.118 0 0 1 -3.1 -1 a 33.118 33.118 0 0 1 -3.1 -1.3 a 33.118 33.118 0 0 1 -2.3 -1.5 a 33.118 33.118 0 0 1 -0.988 -0.727 z"
                        d="M 14.1 60.8 l -7.453 20.02 H 61.71 L 54.3 60.6 a 33.118 33.118 0 0 1 -2.5 1.7 a 33.118 33.118 0 0 1 -3.1 1.5 a 33.118 33.118 0 0 1 -3.011 1.152 a 33.118 33.118 0 0 1 -3.289 1.048 a 33.118 33.118 0 0 1 -3.3 0.6 a 33.118 33.118 0 0 1 -3.3 0.3 a 33.118 33.118 0 0 1 -2.4 0 a 33.118 33.118 0 0 1 -3.3 -0.3 a 33.118 33.118 0 0 1 -3.312 -0.473 a 33.118 33.118 0 0 1 -3.2 -0.8 a 33.118 33.118 0 0 1 -3.1 -1 a 33.118 33.118 0 0 1 -3.1 -1.3 a 33.118 33.118 0 0 1 -2.3 -1.5 a 33.118 33.118 0 0 1 -0.988 -0.727 z"
                        opacity={0.99}
                        fill={data.VM.fill}
                        fillOpacity={0.992}
                        stroke={data.VM.stroke}
                        strokeWidth={1.191}
                        strokeOpacity={0}
                        strokeLinejoin="round"
                        paintOrder="fill markers stroke"
                    />
                    <text
                        y={162.345}
                        x={48.561}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "Simplifica",
                        }}
                        fontWeight={400}
                        fontSize={7.629}
                        fontFamily="Simplifica"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.191}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display Bold'",
                            }}
                            y={162.345}
                            x={48.561}
                            fontWeight={700}
                            fontSize={3.78}
                            fontFamily="Red Hat Display"
                            letterSpacing={-0.093}
                        >
                            {"Visual Management"}
                        </tspan>
                    </text>
                </g>

                <g
                    onMouseMove={(ev) => showTooltip(ev, 'tooltipWO')}
                    onMouseOut={() => hideTooltip('tooltipWO')}
                >
                    <path
                        data-cv-key="6S Workplace Organization"
                        d="M 33 33 H 0 a 33.527 33.527 0 0 0 33 33 z"
                        opacity={0.99}
                        fill={data.WO.fill}
                        stroke={data.WO.stroke}
                        strokeWidth={1.123}
                        strokeOpacity={0}
                        paintOrder="fill markers stroke"
                    />
                    <text
                        y={130.502}
                        x={37.966}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={130.502}
                            x={37.966}
                            letterSpacing={-0.084}
                        >
                            {"Organization"}
                        </tspan>
                    </text>
                    <text
                        y={125.82}
                        x={36.621}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={125.82}
                            x={36.621}
                            letterSpacing={-0.084}
                        >
                            {"6S Workplace"}
                        </tspan>
                    </text>
                </g>
                <g
                    onMouseMove={(ev) => showTooltip(ev, 'tooltipPull')}
                    onMouseOut={() => hideTooltip('tooltipPull')}
                >
                    <path
                        data-cv-key="Pull (Tight Connections)"
                        d="M 34 33 v 33 a 33.527 33.527 0 0 0 34 -33 z"
                        opacity={0.99}
                        fill={data.Pull.fill}
                        fillOpacity={0.992}
                        stroke={data.Pull.stroke}
                        strokeWidth={1.123}
                        strokeOpacity={0}
                        paintOrder="fill markers stroke"
                    />
                    <text
                        y={126.139}
                        x={67.013}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={126.139}
                            x={67.013}
                            letterSpacing={-0.084}
                        >
                            {"Pull (Tight"}
                        </tspan>
                    </text>
                    <text
                        y={130.821}
                        x={66.89}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={130.821}
                            x={66.89}
                            letterSpacing={-0.084}
                        >
                            {"Connections)"}
                        </tspan>
                    </text>
                </g>

                <g
                    onMouseMove={(ev) => showTooltip(ev, 'tooltipSF')}
                    onMouseOut={() => hideTooltip('tooltipSF')}
                >
                    <path
                        data-cv-key="Simple Flow"
                        d="M 33 0 A 33.527 33.527 0 0 0 0 32 h 33 z"
                        opacity={0.99}
                        fill={data.SF.fill}
                        fillOpacity={0.992}
                        stroke={data.SF.stroke}
                        strokeWidth={1.123}
                        strokeOpacity={0}
                        paintOrder="fill markers stroke"
                    />
                    <text
                        y={110.904}
                        x={47.883}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={110.904}
                            x={47.883}
                            letterSpacing={-0.084}
                        >
                            {"Simple"}
                        </tspan>
                    </text>
                    <text
                        y={115.425}
                        x={51.272}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={115.425}
                            x={51.272}
                            letterSpacing={-0.084}
                        >
                            {"Flow"}
                        </tspan>
                    </text>
                </g>

                <g
                    onMouseMove={(ev) => showTooltip(ev, 'tooltipSW')}
                    onMouseOut={() => hideTooltip('tooltipSW')}
                >
                    <path
                        data-cv-key="Standard Work"
                        d="M 34 1 v 31 h 34 A 33.527 33.527 0 0 0 34 0 z"
                        opacity={0.99}
                        fill={data.SW.fill}
                        fillOpacity={0.992}
                        stroke={data.SW.stroke}
                        strokeWidth={1.123}
                        strokeOpacity={0}
                        paintOrder="fill markers stroke"
                    />
                    <text
                        y={111.174}
                        x={67.467}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={111.174}
                            x={67.467}
                            letterSpacing={-0.084}
                        >
                            {"Standard"}
                        </tspan>
                    </text>
                    <text
                        y={115.45}
                        x={67.343}
                        style={{
                            lineHeight: 1.25,
                            InkscapeFontSpecification: "'Red Hat Display, Bold'",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontVariantNumeric: "normal",
                            fontFeatureSettings: "normal",
                            textAlign: "start",
                        }}
                        fontWeight={700}
                        fontSize={3.78}
                        fontFamily="Red Hat Display"
                        letterSpacing={0}
                        wordSpacing={0}
                        fill="#fff"
                        strokeWidth={0.172}
                        transform="translate(-29.412 -86.946)"
                    >
                        <tspan
                            style={{
                                InkscapeFontSpecification: "'Red Hat Display, Bold'",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontVariantNumeric: "normal",
                                fontFeatureSettings: "normal",
                                textAlign: "start",
                            }}
                            y={115.45}
                            x={67.343}
                            letterSpacing={-0.084}
                        >
                            {"Work"}
                        </tspan>
                    </text>
                </g>
            </SVG>
        </>
    )
}

export default FlowCell;
