import React from 'react';
import styled from 'styled-components';
import MultiSelectFlowCell from './MultiSelectFlowCell';
import { Dropdown, RadioButtonGroup, RadioButton, FormGroup } from 'carbon-components-react';
import { FlowCellListContext } from '../../contexts/flowCellList';
import { InventoryFiltersContext } from '../../contexts/inventoryFilters';
import { isEqual } from 'lodash'

const InvRowForm = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: .5em 1em;
    box-sizing: border-box;
    color: var(--title-text);
    font-weight: bold;
    .bx--list-box{
        border-bottom: none;
        background-color: transparent;
    }
    @media (min-width: 950px) {
        display: grid;
        grid-template-columns: 33% 1.1fr 2fr;
    }
`;

const FormRow = styled.div`
    display: grid;
    grid-template-columns: 20% 80%;
    width: calc(100% - 2em);
`;

const FormRowRadio = styled.div`
    display: flex;
    width: calc(100% - 2em);
    align-items: center;
    @media (min-width: 950px) {
        justify-content: flex-end;
    }
`;
const FormField = styled.span`
    display: flex;
    align-items: center;
`;

const SortDropdown = styled(Dropdown)`
        background-color: transparent; 
        border-bottom: none;
        min-width: 15em;
        .bx--list-box__label{
            color: var(--title-text);
        }
        

        button:focus, div:focus{
            outline: none;
        }
`;

const CustomFormGroup = styled(FormGroup)`
    margin-bottom: .5em;
    .bx--radio-button__label{
        font-size: .75rem;

        @media (min-width: 950px) {
            font-size: unset;
        }
    }
    .bx--radio-button__appearance {
        transform: scale(.8);
    }
`;


export default function InventoryRow({ data }) {

    const { setSearchBox, setSortBy, setDisplayNumber, filters: inventoryFilters } = React.useContext(InventoryFiltersContext);
    const { flowCells } = React.useContext(FlowCellListContext);

    const [selectedItems, setSelectedItems] = React.useState([]);
    const [previousSelection, setPreviousSelection] = React.useState([]);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);

    const sortItems = [
        {
            id: 'alphabetical',
            label: 'Alphabetical (A...Z)'
        },
        {
            id: 'huddle',
            label: 'Huddle Order'
        }, {
            id: 'inventoryValue',
            label: 'Inventory $ (desc)'
        }, {
            id: 'inventoryPercent',
            label: 'Inventory % Change (desc)'
        },
        {
            id: 'staffing',
            label: 'Typical Staffing (desc)'
        },
    ];

    const handleSearchSubmit = () => {
        if (!isEqual(selectedItems, previousSelection)) {
            setSearchBox(selectedItems);
            setPreviousSelection(selectedItems);
        }

    }
    const handleSearchChange = e => {
        setSelectedItems(e.selectedItems);
        if (e.selectedItems.length === 0 && !isFilterOpen) {
            setSearchBox([]);
            setPreviousSelection([]);
        }
    }
    const handleRadioChange = e => {
        setDisplayNumber(e);
    }

    return (
        <InvRowForm>
            <FormRow>
                <FormField>Flow Cells: </FormField>
                <MultiSelectFlowCell
                    data={flowCells}
                    onChange={(e) => { handleSearchChange(e) }}
                    closeFunction={handleSearchSubmit}
                    detectOpen={setIsFilterOpen}
                    initialSelectedItems={inventoryFilters.searchBox}
                />
            </FormRow>
            <FormRow>
                <FormField>Sort by: </FormField>
                <SortDropdown
                    ariaLabel="sort by dropdown"
                    id="sortByDropDown"
                    items={sortItems}
                    label="Sort by..."
                    initialSelectedItem={sortItems.find(x => x.id === inventoryFilters.sortBy)}
                    onChange={(e) => setSortBy(e.selectedItem ? e.selectedItem.id : null)}
                />
            </FormRow>
            <FormRowRadio>
                <FormField>Show Measure: </FormField>
                <CustomFormGroup legendText=''>
                    <RadioButtonGroup defaultSelected={inventoryFilters.displayNumber} name='radioSort' onChange={e => handleRadioChange(e)} >
                        <RadioButton
                            value='currentInv'
                            id='currentInv'
                            labelText="Current Inventory"
                        />
                        <RadioButton
                            value='percentToGoal'
                            id='percentToGoal'
                            labelText="% to Goal"
                        />
                        <RadioButton
                            value='percentChange'
                            id='percentChange'
                            labelText="% Change to Prior Day"
                        />
                    </RadioButtonGroup>
                </CustomFormGroup>

            </FormRowRadio>

        </InvRowForm >
    )
}
