import { isSameDay } from "../utils";

//status criteria --> true = green, false = red
//actual < expected = false
//actual > expected = true
//same day, forecast > expected = false
//same day, forecast < expected = true
//same day, progression card = null 

export default function ProcessCompareChart({ data, card, date }) {
    if (!data) return;

    let values = {
        actual: data.data.actual ? data.data.actual : 0,
        expected: data.data.expected ? data.data.expected : 0
    };
    if (card === 'outs') values.forecast = data.data.forecast ? data.data.forecast : 0

    let sameDay = isSameDay(new Date(), date);

    let status;
    if (sameDay) {
        //left off --> compare values based on day and card type and set status. Then return the status 
        if (card === 'progression') status = null;
        if (card === 'outs' && values.forecast > values.expected) status = false;
        if (card === 'outs' && values.forecast < values.expected) status = true;
    } else {
        status = values.actual > values.expected ? true : false
    }

    return {
        status: status,
        values: values,
        accountsPerPerson: data.data.accountsPerPerson
    }


}