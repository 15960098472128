import React from 'react';

export const NotificationContext = React.createContext();


const NotificationProvider = (props) => {

    const statusMap = React.useMemo(() => ({
        error: 'error',
        info: 'info',
        infoSquare: 'info-square',
        success: 'success',
        warning: 'warning',
        warningAlt: 'warning-alt'
    }), []);

    const [notificationProps, setNotificationProps] = React.useState({
        show: false
    });

    const notificationQueue = React.useRef([]);

    const buildNotification = React.useCallback((status, info) => {
        notificationQueue.current.push({
            status: status,
            textToDisplay: info,
            show: true,
            id: `notification-${notificationQueue.current.length}`
        });
        //this state doesnt do anything other than force a render on the index.js for the notifications
        setNotificationProps({
            show: true
        })
    }, [])

    function removeNotification(id) {
        notificationQueue.current = notificationQueue.current.filter(x => x.id !== id);
        setNotificationProps({
            show: false
        })
    }

    return (
        <NotificationContext.Provider value={{ notificationProps, buildNotification, removeNotification, statusMap, notificationQueue }}>
            {props.children}
        </NotificationContext.Provider>
    )
}

export default NotificationProvider;