import React from 'react';
import { navigate } from '@reach/router';
import { login, testCookie, clearCookies } from '../../APIs';


export const UserAuthContext = React.createContext();

const UserAuthProvider = (props) => {

    const authenticateUser = async (data) => {
        try {

            let result = await login(data);
            if (result.data.setNewUser) return result.data;
            localStorage.setItem('user', JSON.stringify(result.data));
            setUser(result.data);
            sessionExpired.current = false;
            navigate('/huddle');

        } catch (err) {
            setUser(null);
            throw err;
        }
    }

    const logout = async (manualLogOut = false) => {
        //displays the session expired notification on log out from expired tokens
        if (!manualLogOut) sessionExpired.current = true;
        try {
            await clearCookies();
            localStorage.removeItem('user');
            setUser(null);
            navigate('/login');
        } catch (error) {
            console.log('error signing out: ', error);
        }

    };

    const checkLoginStatus = async function () {
        try {
            if (user) {
                await testCookie();
                return true;
            } else {
                return false;
            }
        } catch (err) {
            logout(true);
            return false;
        }

    }


    const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')));
    const sessionExpired = React.useRef(false); //used to show notification on login page due to expired session

    return (
        <UserAuthContext.Provider value={{ user, authenticateUser, logout, sessionExpired, checkLoginStatus }}>
            {props.children}
        </UserAuthContext.Provider>
    )
}

export default UserAuthProvider;