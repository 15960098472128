import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled from 'styled-components';
import { isSameDay } from '../../utils';
import { OverallFilterContext } from '../../contexts/overallFilters';

const Container = styled.div`
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        align-items: flex-end;
    `;


const Chart = styled.span`
    padding: 0 .5em;
    min-width: 0;
    flex: 1;
`;

export default function HuddleProgressionMini({ data }) {

    const [chartData, setChartData] = React.useState([]);
    const { filter } = React.useContext(OverallFilterContext);

    React.useEffect(() => {
        const formatChartData = () => {
            if (!data) return {};

            let chartData = data.data;
            const xAxis = chartData.Axes.find(axis => axis.Hierarchies.some(x => x.Name === "Time")).Tuples;
            const yAxis = chartData.Cells;

            let divisor = isSameDay(new Date(), filter.date) ? yAxis.length - 1 : yAxis.length;
            const avg = yAxis.map(x => Math.round(x.Value)).reduce((a, b) => (a + b)) / divisor;

            setChartData(xAxis.map(x => {
                return {
                    date: Date.parse(x.Members[0].Name),
                    value: Math.round(yAxis.find(cell => cell.Ordinal === x.Ordinal).Value),
                    average: avg
                }
            }));


        };
        formatChartData();
    }, [data, filter.date]);


    //forces redraw of chart to avoid cutoff issue
    React.useEffect(() => {
        for (var i = 0; i < Highcharts.charts.length; i++) {
            if (Highcharts.charts[i] !== undefined) {
                Highcharts.charts[i].reflow();
            }
        }
    }, [chartData])


    const options = {

        chart: {
            type: "column",
            margin: 0,
            styledMode: false,
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        plotOptions: {
            series: {
                showInLegend: false
            },
            column: {
                pointPadding: 0,
                borderWidth: 1,
                groupPadding: 0,
                shadow: false
            }
        },
        xAxis: {
            visible: false,
            minPadding: 0,
            maxPadding: 0
        },
        yAxis: {
            visible: false,
            gridLineWidth: 0,
            minPadding: 0,
            maxPadding: 0
        },
        tooltip: {
            split: false,
            useHTML: true,
            outside: true
        },
        legend: {
            enabled: false
        },
        series: [
            {
                id: 'value',
                name: 'value',
                type: "column",
                color: "#B5B9C3",
                pointPadding: 0.1,
                groupPadding: 0,
                borderWidth: 0,
                zIndex: 2,
                tooltip: {
                    headerFormat: '',
                    pointFormatter: function () {
                        var tooltipHtml = '<table width="100%" style="z-index:10 !important;" >';
                        tooltipHtml += '<tr class="VisualizationTooltip"><td width="10%" style="color: #9B9B9B;">Value: </td>';
                        tooltipHtml += '<td width="10%" style="text-align: right;">' + Highcharts.numberFormat(this.y, 0, '.', ',') + '</td>';
                        tooltipHtml += '<td style="text-align: right; padding-left: 10px; font-weight: bold">' + Highcharts.dateFormat('%b %d, %Y', this.x) + '</td>';
                        tooltipHtml += '</tr><tr class="VisualizationTooltip">';
                        tooltipHtml += '<td style="color: #9B9B9B;">Average: </td>';
                        tooltipHtml += '<td width="10%" style="text-align: right;">' + Highcharts.numberFormat(this.average, 0, '.', ',') + '</td>';
                        tooltipHtml += '<td/></tr>';
                        tooltipHtml += '</table>';
                        return tooltipHtml;
                    },
                    valueDecimals: 0,
                    padding: 3
                },
                data: chartData.map(x => {
                    return {
                        name: '',
                        x: x.date,
                        y: x.value,
                        average: x.average
                    }
                })
            },
            {
                id: "average",
                name: "average",
                color: "#EFF3F8",
                data: chartData.map(x => {
                    return {
                        name: '',
                        x: x.date,
                        y: x.average
                    }
                }),
                enableMouseTracking: false,
                marker: { enabled: false },
                type: "area",
                tooltips: { enabled: false },
                zIndex: 1
            },
        ],
        credits: {
            enabled: false
        }
    }

    return (
        <Container>
            <Chart>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    containerProps={{ style: { height: "2.5em", width: "100%" } }}
                />
            </Chart>
        </Container >
    )
}