import React from 'react';

export const InventoryFiltersContext = React.createContext();

const InventoryFiltersProvider = (props) => {

    const [filters, setFilters] = React.useState({
        searchBox: [],
        sortBy: 'huddle',
        displayNumber: 'currentInv'
    });

    const setSearchBox = (items) => {
        setFilters({
            ...filters,
            searchBox: items,
        })
    }

    const setSortBy = (sortBy) => {
        setFilters({
            ...filters,
            sortBy: sortBy,
        })
    }

    const setDisplayNumber = (displayType) => {
        setFilters({
            ...filters,
            displayNumber: displayType
        })
    }

    return (
        <InventoryFiltersContext.Provider value={{ filters, setSearchBox, setSortBy, setDisplayNumber }}>
            {props.children}
        </InventoryFiltersContext.Provider>
    )
}

export default InventoryFiltersProvider;