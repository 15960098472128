import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Form, ComboBox, Dropdown, TextInput, TextArea } from 'carbon-components-react';
import { Hospital32 } from '@carbon/icons-react';
import Calendar from './Calendar';
import { OverallFilterContext } from '../../contexts/overallFilters';
import { addCountermeasure, queryCountermeasuresFormData, multiQuery } from '../../../APIs';
import { NotificationContext } from '../../contexts/notification';
import { formatDateForMDX } from '../../utils';
import { CountermeasuresContext } from '../../contexts/countermeasures';
import { UserAuthContext } from '../../contexts/userAuth';

const Container = styled.div`
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger{
        overflow: hidden;
    }
    .bx--btn--ghost:hover{
        background-color: transparent;
     }
     .bx--btn--ghost:focus{
        box-shadow: none;
     }
    .bx--btn.bx--btn--icon-only.bx--tooltip__trigger:focus{
        border: none;
    }

    .bx--date-picker__input{
        font-size: 1.1rem;
    }

    .bx--modal-container{
        /* height: 100%; */
    }
    .bx--modal{
        justify-content: flex-end;
        align-items: flex-start;
    }

    .bx--label{
        margin: .5rem 0 .25rem 0;
    }
    //combo box dropdown arrow
    .bx--list-box__menu-icon {
        border: none;
        background: transparent;
        padding: 0;
        &:focus {
            outline: none;
        }
    }
`;

const CMBtn = styled(Button)`
    svg,path{
        fill: #FFFFFF !important;
    }

    &:hover{
        svg, path{
            fill: #6DCDB8 !important;
        }

    }
`;

const MultiRowForm = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
`;

const DropDowns = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
`;
export default function AddCounterMeasure(props) {

    const { filter } = React.useContext(OverallFilterContext);
    const { buildNotification, statusMap } = React.useContext(NotificationContext);
    const { queryCMs } = React.useContext(CountermeasuresContext);
    const { user, logout } = React.useContext(UserAuthContext);
    const [open, setOpen] = React.useState(false);
    const [formConfig, setFormConfig] = React.useState({
        ownerList: [],
        dateList: {
            min: new Date(),
            max: new Date()
        }
    })
    const [formValues, setFormValues] = React.useState({
        cmOwner: '',
        cmStatus: '',
        cmProblem: '',
        cmRoot: '',
        cmSolutionTitle: '',
        cmSolutionDetail: '',
        cmDueDate: new Date()
    })

    React.useEffect(() => {
        const getOwners = async () => {
            try {
                let results = await multiQuery({
                    label: 'Countermeasure form',
                    date: null,
                    flowCell: null
                }, queryCountermeasuresFormData);
                setFormConfig({
                    ownerList: results.data.ownerList,
                    dateList: results.data.dateList
                });
            } catch (err) {
                if (err.message === 'Expired session') return await logout();
                setFormConfig({
                    ownerList: [],
                    dateList: {
                        min: new Date(),
                        max: new Date()
                    }
                });
            }


        }
        if (open) getOwners();
    }, [buildNotification, statusMap, open, logout])

    const applyChange = function (ev, id) {
        setFormValues({
            ...formValues,
            [id]: ev !== '' ? ev : null
        });
    };

    const getDate = function (date) {
        let selectedDate = date;
        applyChange(selectedDate, 'cmDueDate');
    }

    const submitForm = async function () {
        const required = ['cmOwner', 'cmStatus', 'cmProblem', 'cmSolutionTitle', 'cmDueDate'];
        formValues.cmDueDate = formatDateForMDX(formValues.cmDueDate);
        let currentForm = JSON.parse(JSON.stringify(formValues));
        required.forEach(x => {
            if (formValues[x] === '') currentForm[x] = null;
        })
        if (!Object.values(currentForm).some(x => x === null)) {
            try {
                await multiQuery({
                    label: 'Add Countermeasure',
                    flowCell: filter.flowCell.id,
                    date: formatDateForMDX(filter.date),
                    user: user.email,
                    formData: currentForm
                }, addCountermeasure)
                buildNotification(statusMap.success, `Successfully added Countermeasure`);
                queryCMs(filter.flowCell.id);
                closeAndClear();
            } catch (err) {
                if (err.message === 'Expired session') return await logout();
                buildNotification(statusMap.error, `Unable to add countermeasure for ${filter.flowCell.name}`);
                setFormValues({ ...currentForm, cmDueDate: new Date(currentForm.cmDueDate) })
            }

        } else {
            setFormValues({ ...currentForm, cmDueDate: new Date(currentForm.cmDueDate) })
        }
    };

    const closeAndClear = function () {
        setFormValues({
            cmOwner: '',
            cmStatus: '',
            cmProblem: '',
            cmRoot: '',
            cmSolutionTitle: '',
            cmSolutionDetail: '',
            cmDueDate: new Date()
        });
        setOpen(false);
    }

    const statusItems = [
        {
            id: 'siNotStarted',
            label: 'Not started'
        },
        {
            id: 'siOnTrack',
            label: 'On track'
        },
        {
            id: 'siBehind',
            label: 'Behind'
        },
        {
            id: 'siCancelled',
            label: 'Cancelled'
        },
    ];

    return (
        <Container>
            <CMBtn
                renderIcon={Hospital32}
                iconDescription="Add Counter Measure"
                hasIconOnly
                kind='ghost'
                onClick={() => setOpen(true)}
            />
            <Modal
                open={open}
                onRequestClose={() => setOpen(false)}
                primaryButtonText="Submit"
                onRequestSubmit={submitForm}
                secondaryButtonText="Cancel"
                onSecondarySubmit={closeAndClear}
                modalHeading="ADD COUNTERMEASURE"
                hasForm={true}
                preventCloseOnClickOutside
            >
                <Form >
                    <MultiRowForm>
                        <DropDowns>
                            <ComboBox
                                id="cmOwner"
                                items={formConfig.ownerList}
                                placeholder=""
                                titleText="Owner"
                                light
                                selectedItem={formValues.cmOwner}
                                onChange={e => {
                                    applyChange(e.selectedItem ? e.selectedItem.label : null, 'cmOwner')
                                }}
                                invalid={formValues.cmOwner === null}
                            />
                            <Dropdown
                                id='cmStatus'
                                items={statusItems}
                                label=''
                                titleText='Status'
                                selectedItem={formValues.cmStatus}
                                onChange={e => applyChange(e.selectedItem.label, 'cmStatus')}
                                invalid={formValues.cmStatus === null}
                            />
                        </DropDowns>
                        <Calendar
                            label="Due Date:"
                            fct={getDate}
                            passInDate={formValues.cmDueDate}
                            ranges={formConfig.dateList}
                        />
                    </MultiRowForm>
                    <TextInput
                        id="cmProblem"
                        labelText="Problem"
                        value={formValues.cmProblem}
                        onChange={e => applyChange(e.target.value, 'cmProblem')}
                        invalid={formValues.cmProblem === null}
                    />
                    <TextInput
                        id="cmRoot"
                        labelText="Root Cause (optional)"
                        value={formValues.cmRoot}
                        onChange={e => applyChange(e.target.value, 'cmRoot')}
                    />
                    <TextInput
                        id="cmSolutionTitle"
                        labelText="Solution Title"
                        value={formValues.cmSolutionTitle}
                        onChange={e => applyChange(e.target.value, 'cmSolutionTitle')}
                        invalid={formValues.cmSolutionTitle === null}
                    />
                    <TextArea
                        id="cmSolutionDetail"
                        labelText="Solution Detail (optional)"
                        value={formValues.cmSolutionDetail}
                        onChange={e => applyChange(e.target.value, 'cmSolutionDetail')}
                    />
                </Form>
            </Modal>
        </Container>

    )
}
