import React from 'react';
import styled from 'styled-components';
import { DatePicker, DatePickerInput } from 'carbon-components-react';


const Container = styled.div`
    .bx--form-item {
        flex: unset;
    }
    .bx--date-picker-input__wrapper{
        input, svg {
            background-color: ${props => props.headerCSS === 1 ? "transparent" : 'initial'};
            color: ${props => props.headerCSS === 1 ? "#FFFFFF" : 'initial'};
            fill: ${props => props.headerCSS === 1 ? "#FFFFFF" : 'initial'};
            border-bottom: none;
            &:focus {
                outline: none;
            }
        }
        .bx--date-picker__input{ 
            width: 11em;
            padding: 0;
            font-size: .75rem;
            outline: none;
        }

        @media(min-width: 700px) {
            .bx--date-picker__input { 
                width: unset;
                font-size: unset;
            }
        }
    }
`;
export default function Calendar({ label = null, fct, passInDate = null, headerCalendar = false, ranges = null }) {
    //label --> value shown for the calendar input label
    //fct --> function to run when date is changed. REQUIRED
    //passInDate --> specifies the starting date of component 
    //headerCalendar --> specifies if the calendar is the main header for css changes
    //ranges --> used if setting limit for calendar {min: date, max: date}
    const [calendar, setCalendar] = React.useState(passInDate ? passInDate : new Date());

    React.useEffect(() => {
        setCalendar(passInDate);
    }, [passInDate])

    function changeDate(date) {
        let value = date[0];
        fct(value);
        setCalendar(new Date(value));
    }

    return (
        <Container
            headerCSS={headerCalendar ? 1 : 0}
        >
            <DatePicker
                datePickerType="single"
                allowInput={false}
                dateFormat='M d, Y'
                value={calendar}
                onChange={(d) => changeDate(d)}
                locale='en'
                minDate={ranges && ranges.min ? new Date(ranges.min) : ''}
                maxDate={ranges && ranges.max ? new Date(ranges.max) : ''}
            >
                <DatePickerInput
                    placeholder="mm/dd/yyyy"
                    labelText={label ? label : 'Date picker'}
                    hideLabel={label ? false : true}
                    title=''
                    id={`date-picker-single-${Math.floor(Math.random() * 10000) + 1}`} //id is required but this is a component that can be used more than once per page
                />

            </DatePicker>
        </Container>
    )
}
