import React from 'react';
import { multiQuery, queryCountermeasures } from '../../APIs';
import { OverallFilterContext } from './overallFilters';
import { UserAuthContext } from './userAuth';

export const CountermeasuresContext = React.createContext();


const CountermeasuresProvider = (props) => {

    const { filter } = React.useContext(OverallFilterContext);
    const { logout } = React.useContext(UserAuthContext);
    const [CMs, setCMs] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);


    React.useEffect(() => {
        if (filter.page === '/countermeasures') {
            queryCMs(filter.flowCell.id);
        } else {
            setCMs([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    const queryCMs = async function (flowCell) {
        try {
            setIsLoading(true);
            let results = await multiQuery({
                label: 'Countermeasures',
                flowCell: flowCell
            }, queryCountermeasures);
            setCMs(results.data)
        } catch (err) {
            if (err.message === 'Expired session') return await logout();
            setCMs([]);
        } finally {
            setIsLoading(false);
        }

    }

    return (
        <CountermeasuresContext.Provider value={{ CMs, queryCMs, isLoading }}>
            {props.children}
        </CountermeasuresContext.Provider>
    )
}

export default CountermeasuresProvider;