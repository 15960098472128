const axios = require('axios');



// takes a label for logging purposes, and any number of queries
export async function multiQuery(params, ...queries) {
    //params is an object of parameters the query will use. Must include a "label" property
    await testCookie();
    return await Promise.allSettled(
        queries.map(query => query(params))
    ).then(async results => {
        results.forEach((result, i) => {
            if (result.status === 'rejected') {
                let label = params.label ? params.label : '';
                throw Error(`${label} query ${i} failed`); //null or undefined
            }
        });
        let returnData = await Promise.resolve(results.map(x => x.value));
        return returnData.length === 1 ? returnData[0] : returnData
    }).catch(err => {
        if (err.message === 'Expired session') throw err.message;
        // error is rethrown and needs to be caught to build a notification elsewhere 
        throw Error(`An error has occured while loading data. ${err}`);
    });
}


//------------------ HUDDLE START ----------------------//

export function queryHuddleProgression30DayExpected({ date, flowCell }) {
    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/progression/production-expected30day`,
        headers: {},
        params: params,
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}

export function queryHuddleProgression30DayActual({ date, flowCell }) {
    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/progression/production-actual30day`,
        headers: {},
        params: params,
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}

export function queryHuddleOuts30DayActual({ date, flowCell }) {
    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/outs/production-actual30day`,
        headers: {},
        params: params,
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}

export function queryHuddleOuts30DayExpected({ date, flowCell }) {
    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/outs/production-expected30day`,
        headers: {},
        params: params,
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}

export function queryHuddleIns30Day({ date, flowCell }) {
    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/outs/production-ins30day`,
        headers: {},
        params: params,
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}
export async function queryParetoChart({ date, flowCell }) {
    //date: current selected date
    //flowCell: current selected flow cell

    let params = {
        date: date,
        flowCell: flowCell
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/pareto/get-pareto`,
        headers: {},
        params: params,
        withCredentials: true
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }


}

export async function addParetoCondition({ flowCell, condition, conditionDate, createDate, createUser }) {
    //flowCell: name of flow cell
    //condition: id of condition
    //conditionDate: selected date for added pareto
    //createDate:  date of function call
    //createUser: user who added condition


    let params = {
        flowCell: flowCell,
        condition: condition,
        conditionDate: conditionDate,
        createDate: createDate,
        createUser: createUser
    }

    const config = {
        method: 'post',
        url: `https://${process.env.REACT_APP_API}/huddle/pareto/add-pareto`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        if (res.status === 200 || res.status === 204) return true;
    } catch (err) {
        throw err;
    }
}


export async function updateStaffing({ flowCell, date, currentDate, inputVal }) {

    let params = {
        flowCell: flowCell,
        date: date,
        currentDate: currentDate,
        value: inputVal
    }

    const config = {
        method: 'post',
        url: `https://${process.env.REACT_APP_API}/huddle/staffing/update-staffing`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        if (res.status === 200 || res.status === 204) return true;
    } catch (err) {
        throw err;
    }
}

export async function queryStaffing({ date, flowCell }) {
    //date: current selected date
    //flowCell: current selected flow cell

    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/staffing/staffing`,
        headers: {},
        params: params,
        withCredentials: true
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryStaffingChart({ date, flowCell }) {
    //date: current selected date
    //flowCell: current selected flow cell

    let params = {
        date: date,
        flowCell: flowCell,
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/staffing/barchart`,
        headers: {},
        params: params,
        withCredentials: true
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryOutsActualVsExpected({ flowCell, date }) {
    //flowCell: name of flow cell
    //date: selected date

    let params = {
        flowCell: flowCell,
        date: date
    }

    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/outs/actualVsExpected`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryProgressionActualVsExpected({ flowCell, date }) {
    //flowCell: name of flow cell
    //date: selected date

    let params = {
        flowCell: flowCell,
        date: date
    }

    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/huddle/progression/actualVsExpected`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}



//------------------ HUDDLE END ----------------------//

//------------------ INVENTORY START ----------------------//

export function queryInventoryFlowCell() {

    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/inventory/get-flowcell`,
        headers: {},
        withCredentials: true,
    };

    let res;
    try {
        res = axios(config);
    } catch (err) {
        return err;
    }

    return res;
}


export async function queryInventoryLineChart({ date, flowCell, sameDay }) {

    let params = {
        date: date,
        flowCell: flowCell,
        sameDay: sameDay
    }

    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/inventory/inventory-line`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

//------------------ INVENTORY END ----------------------//

//------------------ COUNTERMEASURE START----------------------//

export async function queryCountermeasuresFormData() {
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/countermeasures/get-cm-form-data`,
        headers: {},
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryCountermeasures({ flowCell }) {

    let params = {
        flowCell: flowCell
    }

    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/countermeasures/get-countermeasures`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function addCountermeasure({ flowCell, date, user, formData }) {
    //flowCell: name of flow cell
    //date: selected date
    //user: username of person who created CM
    //formData:  the CM form

    let params = {
        flowCell: flowCell,
        date: date,
        user: user,
        formData: formData
    }

    const config = {
        method: 'post',
        url: `https://${process.env.REACT_APP_API}/countermeasures/add-countermeasure`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        if (res.status === 200 || res.status === 204) return;
    } catch (err) {
        throw err;
    }
}

//------------------ COUNTERMEASURE END----------------------//


//------------------ GENERAL START----------------------//

export async function login(data) {
    try {

        const config = {
            method: 'post',
            url: `https://${process.env.REACT_APP_API}/login`,
            withCredentials: true,
            auth: data
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function completeRegister(data) {
    try {

        let config = {
            method: 'post',
            url: `https://${process.env.REACT_APP_API}/completeRegister`,
            auth: {
                username: data.userName,
                password: data.newPassword
            },
            data: {
                firstName: data.firstName,
                lastName: data.lastName,
                oldPassword: data.oldPassword
            }
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function forgotPassword(login) {
    try {

        let config = {
            method: 'post',
            url: `https://${process.env.REACT_APP_API}/forgotPassword`,
            data: {
                login: login
            }
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function resetPassword(login, pwd, code) {
    try {

        let config = {
            method: 'post',
            url: `https://${process.env.REACT_APP_API}/resetPassword`,
            auth: {
                username: login,
                password: pwd
            },
            data: {
                code: code
            }
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function clearCookies(data) {
    try {
        const config = {
            method: 'get',
            url: `https://${process.env.REACT_APP_API}/logout`,
            headers: {},
            withCredentials: true,
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function testCookie(data) {
    try {
        const config = {
            method: 'get',
            url: `https://${process.env.REACT_APP_API}/test`,
            // headers: {},
            withCredentials: true,
        }

        let res = await axios(config);
        return res;
    } catch (err) {
        throw Error('Expired session');
    }
}


export async function queryFlowCells() {
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/flowCellList`,
        headers: {},
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryFlowCellData({ flowCell }) {

    const params = {
        flowCell: flowCell
    }
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/flowCellData`,
        headers: {},
        params: params,
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }
}

export async function queryRolling30BusinessDays() {
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/rolling30Days`,
        headers: {},
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }


}

export async function queryAdminURL() {
    const config = {
        method: 'get',
        url: `https://${process.env.REACT_APP_API}/adminURL`,
        headers: {},
        withCredentials: true,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (err) {
        throw err.response;
    }


}


//------------------ GENERAL END----------------------//






