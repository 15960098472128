import React from 'react';
import { multiQuery, queryFlowCells } from '../../APIs';
import { UserAuthContext } from './userAuth';

export const FlowCellListContext = React.createContext();

function processFlowCells(flowCells) {
    let returnData = [];
    //custom created flow cell to indicate no filters on flow cell when making queries
    returnData.push({
        id: '[Flow Cells].[Flow Cells].[All Flow Cells]',
        name: 'All Flow Cells'
    });
    if (flowCells.Elements) {
        flowCells.Elements.forEach(x => {
            returnData.push({
                id: x.UniqueName,
                name: x.Name
            })
        })
    }
    return returnData;


}

const FlowCellListProvider = (props) => {

    const [flowCells, setFlowCells] = React.useState([]);
    const { logout } = React.useContext(UserAuthContext);

    React.useEffect(() => {
        const queryFlowCellList = async () => {
            try {
                let results = await multiQuery({
                    label: 'Flow Cell List',
                    date: null,
                    flowCell: null
                }, queryFlowCells);
                setFlowCells(processFlowCells(results.data));
            } catch (err) {
                if (err.message === 'Expired session') return await logout();
                setFlowCells([]);
            }

        };
        queryFlowCellList();

    }, [logout]);


    return (
        <FlowCellListContext.Provider value={{ flowCells }}>
            {props.children}
        </FlowCellListContext.Provider>
    )
}

export default FlowCellListProvider;